import React, { Component } from 'react';
import { apiUrl } from '../../globalVariables';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const options = ["01/02/2023", "01/03/2023", "01/05/2023",
    "01/09/2023", "Undefined"];
const defaultOption = options[0];

class CohortRetention extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        this.state = {
            data: [],
            currentData: [],
            dataLoading: true,
            user: user,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
            usersActivated: "",
        };
    }

    componentDidMount() {
        this.getCohortRetention();
    }

    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };
    _onSelect = (cohort) => {
        const cohortName = cohort.value;
        // Set the data to display
        const cohortData = this.state.data["cohort"][cohortName];
        const activatedData = this.state.data["activated"][cohortName];
        this.setState({
            currentData: cohortData,
            usersActivated: activatedData,
        });
    }

    getCohortRetention = () => {
        const url = new URL(apiUrl + "/admin/get-cohort-retention");
        const params = { userId: this.state.user.uid };
        Object.keys(params).forEach(
            (key) => url.searchParams.append(key, params[key]),
        );
        fetch(url)
            .then((response) => response.json()).then((data) => {
                const result = data.data;
                if (!result || result == null ||
                    !data.metadata || data.metadata.result !== "success") {
                    // TODO: Log this error
                } else {
                    // Get result and format
                    if (!result) return;
                    const final = {
                        "cohort": {
                            "01/02/2023": [],
                            "01/03/2023": [],
                            "01/05/2023": [],
                            "01/09/2023": [],
                            "Undefined": [],
                        },
                        "activated": {
                            "01/02/2023": "",
                            "01/03/2023": "",
                            "01/05/2023": "",
                            "01/09/2023": "",
                            "Undefined": "",
                        },
                    }
                    const usageNameMap = ["First Usage", "Second Usage", "Third Usage",
                        "Fourth Usage", "Fifth Usage", "Sixth Usage ++"];

                    const cohortRet = result.cohortRetention;
                    const activatedCount = result.activatedCount;
                    for (const cohortName in cohortRet) {
                        const cohortData = cohortRet[cohortName];
                        for (let i = 0; i < cohortData.length; i++) {
                            const cohortInstance = {
                                usage: usageNameMap[i],
                                "Usage Count": cohortData[i],
                            };
                            final["cohort"][cohortName].push(cohortInstance);
                            final["activated"][cohortName] = activatedCount[cohortName];
                            

                        }
                    }
                    this.setState({
                        currentData: final["cohort"]["01/02/2023"],
                        usersActivated: final["activated"]["01/02/2023"],
                        data: final,
                        dataLoading: false,
                    });
                }
            }).catch((error) => {
                // TODO: Log this issue
                console.log(error);
                this.setState({
                    data: [],
                    dataLoading: false,
                });
            });
    }

    openNewTab = (url) => {
        window.open(url, '_blank');
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        })
    }

    render() {
        return (
            <div className="DashboardVerticalSection">
                <h2>Retention (Weekly Usage Count)</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <div className="HorizontalFlex CenterContentsVerticalInHorizontalFlex">
                        <p className="TitleText">Cohort:</p>
                        <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select Cohort" />
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <p className="TitleText">Users Activated:</p>
                        <p className="TitleTextValue">{this.state.usersActivated}</p>
                    </div>

                    <br/>
                    
                    <ResponsiveContainer width="100%" height="90%">
                        <LineChart
                        width={500}
                        height={300}
                        data={this.state.currentData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="usage" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Usage Count" stroke="#8884d8" activeDot={{ r: 8 }} />
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* {this.openProjectModal()} */}
                
            </div>
        );
    }
}


export default CohortRetention;
