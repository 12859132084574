import React, { Component } from 'react';
import { apiUrl } from '../../globalVariables';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class EmailsPerDay extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        this.state = {
            data: [],
            dataLoading: true,
            user: user,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {
        this.getNumberOfEmailsPerHour();
    }

    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };

    getNumberOfEmailsPerHour = () => {
        const url = new URL(apiUrl + "/admin/get-count-per-day");
        const params = { userId: this.state.user.uid };
        Object.keys(params).forEach(
            (key) => url.searchParams.append(key, params[key]),
        );
        fetch(url)
            .then((response) => response.json()).then((data) => {
                const collection = data.data;
                if (!collection || collection == null ||
                    !data.metadata || data.metadata.result !== "success") {
                    // TODO: Log this error
                } else {
                    // Iterate through collection and date and time.
                    if (!collection.length) return;
                    const final = [];
                    for (let i = 0; i < collection.length; i++) {
                        const req = collection[i];
                        const id = Number(req.date);
                        const date = new Date(id);

                        const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
                        
                        const dayIndex = date.getDay();

                        const day = weekdays[dayIndex];
                        const dayNum = date.getDate();
                        const month = date.getMonth() + 1;
                        // const year = date.getFullYear();

                        const formattedDate = `${day}/${month}/${dayNum}`

                        const format = {
                            date: formattedDate,
                            "Number of emails generated": req.count,
                        };

                        final.push(format);

                    }
                    
                    this.setState({
                        data: final,
                        dataLoading: false,
                    });
                }
            }).catch((error) => {
                // TODO: Log this issue
                console.log(error);
                this.setState({
                    data: [],
                    dataLoading: false,
                });
            });
    }

    openNewTab = (url) => {
        window.open(url, '_blank');
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        })
    }

    render() {
        return (
            <div className="DashboardVerticalSection">
                <h2> Number of Emails Generated Per Day  </h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                        width={500}
                        height={300}
                        data={this.state.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="Number of emails generated" fill="#8884d8" />
                        {/* <Line type="monotone" dataKey="Number of emails generated" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>

                {/* {this.openProjectModal()} */}
                
            </div>
        );
    }
}


export default EmailsPerDay;
