import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
let globalData = [];

class RequestTypeCount extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        const adminMetrics = this.props.adminMetrics ?
            this.props.adminMetrics : {};
        this.state = {
            data: [],
            dataLoading: true,
            user: user,
            adminMetrics: adminMetrics,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {

    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };

    getValFromAdminMetrics = (metrics) => {
        const data = [];

        for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];
            const docId = metric.docId;
            if (docId === "request-type-count") {
                const keys = Object.keys(metric);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    if (key === "docId") continue;
                    const val = metric[key];
                    data.push({
                        name: key,
                        value: val,
                    });

                }
            }
        }
        return data;
    }
    toTitleCase = (str) => {
        return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          }
        );
      }

    render() {
        const data = this.getValFromAdminMetrics(this.props.adminMetrics);
        globalData = data;
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);
          
            return (
              <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}% ${this.toTitleCase(globalData[index].name)}`}
              </text>
            );
          };
        return (
            <div className="DashboardVerticalSection">
                <h2>Generated Email Types</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={500} height={300}>
                    <Pie
                        data={data}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={170}
                        fill="#8884d8"
                        nameKey="name"
                        dataKey="value">
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                    </PieChart>
                </ResponsiveContainer>
                </div>
            </div>
        );
    }
}


export default RequestTypeCount;
