import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


class StatusCount extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        const adminMetrics = this.props.adminMetrics ?
            this.props.adminMetrics : {};
        this.state = {
            data: [],
            dataLoading: true,
            user: user,
            adminMetrics: adminMetrics,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {

    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };

    getValFromAdminMetrics = (metrics) => {
        const data = [];

        for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];
            const docId = metric.docId;
            if (docId === "status-count") {
                const keys = Object.keys(metric);
                for (let j = 0; j < keys.length; j++) {
                    const key = keys[j];
                    if (key === "docId") continue;
                    const val = metric[key];
                    data.push({
                        name: key,
                        value: val,
                    });

                }
            }
        }
        return data;
    }

    render() {
        const data = this.getValFromAdminMetrics(this.props.adminMetrics)
        return (
            <div className="DashboardVerticalSection">
                <h2>Generated Emails Status</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8" />
                        {/* <Line type="monotone" dataKey="Number of emails generated" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </BarChart>
                    </ResponsiveContainer>
                </div>


                
            </div>
        );
    }
}


export default StatusCount;
