import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

class NumUsersMonthly extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        this.state = {
            data: [],
            dataLoading: true,
            user: user,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };

    getValFromAdminMetrics(metrics) {
        let finalData = [];
        for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];
            const docId = metric.docId;
            console.log(docId);
            if (docId === "monthly-users") {
                let mondays = Object.keys(metric);
                mondays.sort();
                for (let j = 0; j < mondays.length; j++) {
                    console.log(mondays[j]);
                    if (mondays[j] !== "docId") {
                        const totalUsersEveryMonday = metric[mondays[j]];
                        const dataInstance = {
                            "Number of Users": Number(totalUsersEveryMonday),
                            "Month of": mondays[j],
                        }
                        finalData.push(dataInstance);
                    }
                    
                }
            }
        }
        return finalData;
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        })
    }

    render() {
        const data = this.getValFromAdminMetrics(this.props.adminMetrics)
        return (
            <div className="DashboardVerticalSection">
                <h2>Monthly User Growth</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={500}
                        height={300}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="Month of" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Number of Users" stroke="#8884d8" activeDot={{ r: 8 }} />
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* {this.openProjectModal()} */}
                
            </div>
        );
    }
}


export default NumUsersMonthly;
