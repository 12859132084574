import s from "../../styles/Content.module.css"
import Header from "./Header";
// import PeopleCardContainer from "./PeopleCardContainer";
// import ReachStatistics from "./ReachStatistics";
// import GenderGeoStatistics from "./GenderGeoStatistics";
import {
    getAuth, signInWithCustomToken,
    signInWithEmailAndPassword
} from "firebase/auth";
import { apiUrl } from '../../globalVariables';
import React, { Component } from 'react';
import EmailsPerHour from "./EmailsPerHour";
import CohortRetention from "./CohortRetention";
import EmailsPerDay from "./EmailsPerDay";
import NumInvites from "./NumInvites";
import NumActivated from "./NumActivated";
import NumChromeInstalls from "./NumChromeInstalls";
import NumRegistered from "./NumRegistered";
import CohortRetentionDaily from "./CohortRetentionDaily";
import StatusCount from "./StatusCount";
import RequestTypeCount from "./RequestTypeCount";
import NumEmailsGenerated from "./NumEmailsGenerated";
import NumUsersWeekly from "./NumUsersWeekly";
import NumUsersMonthly from "./NumUsersMonthly";
import NumEmailsDataset from "./NumEmailsDataset";
import WeekdayDistributionOfEmailVolume from "./WeekdayDistributionOfEmailVolume";

class Content extends Component {
    constructor(props) {
        super(props);

        // Skip login for investors with specific password
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const bypass = urlParams.get("bypass");

        this.state = {
            loginStatisfied: bypass === "a4452b01" ? true : false,
            username: "",
            password: "",
            errorText: "",
            signUpLoading: true,
            adminMetrics: {},
            renderLogin: false,
            bypass: bypass,
            user: bypass === "a4452b01" ? {
                uid: "a4452b01",
            } : {},
        };
    }

    onUsernameFormSubmit = (event) => {
        event.preventDefault();
        const email = this.state.username.toString().toLowerCase();
        const password = this.state.password;

        // const validatePassword = this.validatePassword(password);
        // const validateUsername = this.validateUsername(username);
        // if (!validateUsername || !validatePassword ||
        //     this.state.usernameBanned) {
        //     // Show error and return
        //     return;
        // }
        // Everything OK, Make a post request.
        this.signInWithPassword(email, password);
    };

    componentDidMount() {
        this.updateAuth();
        // if bypass, then get metrics
        if (this.state.bypass && this.state.bypass.length &&
            this.state.bypass.length > 0) {
            this.getAdminMetrics(this.state.user);
        }
    }

    updateAuth = () => {
        const auth = getAuth();
        auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in
                // const uid = user.uid.toString();
                this.validateAdmin(user);
                this.getAdminMetrics(user);
            } else {
                // No user
                this.setState({
                    renderLogin: true,
                })
            }
        });
    };

    signInWithPassword = (email, password) => {
        if (!email || !password) return;
        const auth = getAuth();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                this.setState({
                    signUpLoading: false,
                    user: userCredential,
                });
                this.validateAdmin(userCredential.user);
            })
            .catch((error) => {
                const errorMessage = error.message;
                let errorToShow = "Sorry something went wrong";
                if (errorMessage.includes("user-not-found")) {
                    errorToShow =
                        "Sorry we could not find an account for this username";
                }
                if (errorMessage.includes("wrong-password")) {
                    errorToShow =
                        "Incorrect Password";
                }
                this.setState({
                    signUpLoading: false,
                    loginStatisfied: false,
                    errorText: errorToShow,
                    renderLogin: true,
                });
            });
    };

    getAdminMetrics = (user) => {

        const url = new URL(apiUrl + "/admin/get-admin-metrics");
        const params = { userId: user.uid };
        Object.keys(params).forEach(
            (key) => url.searchParams.append(key, params[key]),
        );
        fetch(url)
            .then((response) => response.json()).then((data) => {
                const collection = data.data;
                if (!collection || collection == null ||
                    !data.metadata || data.metadata.result !== "success") {
                    // TODO: Log this error
                } else {
                    this.setState({
                        adminMetrics: collection,
                        dataLoading: false,
                    });
                }
            }).catch((error) => {
                console.log(error);
                this.setState({
                    data: [],
                    dataLoading: false,
                });
            });
    }


    validateAdmin = (user) => {
        const userId = user.uid;
        const loginUrl = new URL(apiUrl + "/admin/login");
        const data = {
            userId: userId,
        };

        fetch(loginUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((response) => response.json())
            .then((response) => {
                if (response.metadata.result &&
                    response.metadata.result === "success") {

                    this.signInWithToken(response, user);

                } else {
                    this.setState({
                        signUpLoading: false,
                        errorText: "Sorry something went wrong." +
                            " Please try again",
                    });

                }
            }).catch((error) => {
                // TODO: Log this error
                console.error(error);
                this.setState({
                    signUpLoading: false,
                    errorText: "Sorry something went wrong." +
                        " Please try again",
                });
            });
    }

    onSignInDone = (user) => {
        // Open the main dashboard

        this.setState({
            loginStatisfied: true,
        })
        this.getAdminMetrics(user)
    }

    signInWithToken = (data, user) => {
        const auth = getAuth();
        const token = data.data.token;
        signInWithCustomToken(auth, token).then((userCredential) => {
            // Signed In
            this.setState({
                signUpLoading: false,
                renderLogin: false,
                user: user,

            });
            this.onSignInDone(user);
        }).catch((error) => {
            // Error signing in
            this.setState({
                signUpLoading: false,
                renderLogin: true,
                errorText: "Sorry something went wrong. You may be unauthorized",
            });
            // console.error(error);
            // TODO: Log This
        });
    };

    handlePasswordChange = (e) => {
        const val = e.target.value;
        this.setState({
            password: val,
        });
    };
    handleUsernameChange = (e) => {
        const val = e.target.value;
        this.setState({
            username: val,
        });
    };

    render() {
        return (
            this.state.loginStatisfied ? (
                <div className={`${s.content} content`}>
                    <Header key={this.state.loginStatisfied} user={this.state.user} />
                    {/* <PeopleCardContainer />
                    <ReachStatistics /> */}
                    {/* <GenderGeoStatistics /> */}
                    <div className=".container-fluid">
                        <div className="row">
                            <div className="col-lg-6 HorizontalFlex">
                                <div>
                                    {/* num of emails generated */}
                                    <NumEmailsGenerated key={this.state.loginStatisfied}
                                        adminMetrics={this.state.adminMetrics}
                                        user={this.state.user} />
                                </div>

                            </div>

                            <div className="col-lg-6">
                                <div className="HorizontalFlex">

                                    <div>
                                        {/* num of invites sent */}
                                        <NumInvites key={this.state.loginStatisfied}
                                            adminMetrics={this.state.adminMetrics}
                                            user={this.state.user} />
                                    </div>
                                    <div>
                                        {/* num of activate*/}
                                        <NumActivated key={this.state.loginStatisfied}
                                            adminMetrics={this.state.adminMetrics}
                                            user={this.state.user} />
                                    </div>

                                </div>

                                <div className="HorizontalFlex">
                                    <div>
                                        {/* num of chrome installs & uninstalls */}
                                        <NumChromeInstalls key={this.state.loginStatisfied}
                                            adminMetrics={this.state.adminMetrics}
                                            user={this.state.user} />
                                    </div>
                                    <div>
                                        {/* num of registered (created account)*/}
                                        <NumRegistered key={this.state.loginStatisfied}
                                            adminMetrics={this.state.adminMetrics}
                                            user={this.state.user} />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <NumUsersMonthly key={this.state.loginStatisfied} user={this.state.user}
                                    adminMetrics={this.state.adminMetrics} />
                            </div>
                            <div className="col-lg-6">
                                {/* num of emails in dataset */}
                                <NumEmailsDataset key={this.state.loginStatisfied}
                                    adminMetrics={this.state.adminMetrics}
                                    user={this.state.user} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <EmailsPerDay key={this.state.loginStatisfied} user={this.state.user} />
                            </div>
                            <div className="col-lg-6">
                                <EmailsPerHour key={this.state.loginStatisfied} user={this.state.user} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <WeekdayDistributionOfEmailVolume key={this.state.loginStatisfied}
                                    adminMetrics={this.state.adminMetrics} user={this.state.user}/>
                            </div>
                            <div className="col-lg-6">
                                
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6">
                                <CohortRetention key={this.state.loginStatisfied} user={this.state.user} />
                            </div>

                            <div className="col-lg-6">
                                <CohortRetentionDaily key={this.state.loginStatisfied} user={this.state.user} />
                            </div>

                        </div>

                        {/* Data for email status and type counts */}
                        <div className="row">
                            <div className="col-lg-6">
                                <StatusCount key={this.state.loginStatisfied}
                                    adminMetrics={this.state.adminMetrics}
                                    user={this.state.user} />
                            </div>

                            <div className="col-lg-6">
                                <RequestTypeCount key={this.state.loginStatisfied}
                                    adminMetrics={this.state.adminMetrics}
                                    user={this.state.user} />
                            </div>

                        </div>

                    </div>

                </div>
            ) : this.state.renderLogin && (
                <div className="SignUpMethodContainer">
                    <div>
                        <h2>
                            Admin Login
                        </h2>
                    </div>
                    <div>
                        <form className="SignUpForm"
                            onSubmit={this.onUsernameFormSubmit}>
                            <input className="InputSignUp"
                                autoComplete="off" autoCapitalize="off"
                                placeholder="Username"
                                onChange={(e) =>
                                    this.handleUsernameChange(e)}
                                value={this.state.username}
                                type="text" name="name" />

                            <input className="InputSignUp"
                                autoComplete="off" autoCapitalize="off"
                                placeholder="Password"
                                onChange={(e) =>
                                    this.handlePasswordChange(e)}
                                value={this.state.password}
                                type="password" name="password" />

                            <div className="SignUpErrorTextContainer">
                                <p className="ReviewErrorText">
                                    {this.state.errorText}
                                </p>
                                {/* {this.state.errors} */}
                            </div>

                            <button className="SignUpFormSubmitButton"
                                type="submit">
                                <p className="SignInButtonText">
                                    Log in</p>

                            </button>
                        </form>
                    </div>


                </div>
            )

        )
    }
}
export default Content;