import React, { Component } from 'react';
import { apiUrl } from '../../globalVariables';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import Dropdown from "react-dropdown";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const options = ["Last 24 hours", "Last 7 days", "Last 30 days",
    "Last 3 months", "All time"];
const defaultOption = options[0];
const rangeMap = {
    "Last 24 hours": {
        hours: 23, // Most recent hour might not be completed yet.
    }, "Last 7 days": {
        hours: 168,
    }, "Last 30 days": {
        hours: 720,
    }, "Last 3 months": {
        hours: 2160
    }, "All time": {
        hours: Number.MAX_VALUE,
    }
}

class EmailsPerHour extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        this.state = {
            data: [],
            selectedData: [],
            dataLoading: true,
            user: user,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {
        this.getNumberOfEmailsPerHour();
    }

    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };

    getNumberOfEmailsPerHour = () => {
        const url = new URL(apiUrl + "/admin/get-count-per-hour");
        const params = { userId: this.state.user.uid };
        Object.keys(params).forEach(
            (key) => url.searchParams.append(key, params[key]),
        );
        fetch(url)
            .then((response) => response.json()).then((data) => {
                const collection = data.data;
                if (!collection || collection == null ||
                    !data.metadata || data.metadata.result !== "success") {
                    // TODO: Log this error
                } else {
                    // Iterate through collection and date and time.
                    if (!collection.length) return;
                    const final = [];
                    for (let i = 0; i < collection.length; i++) {
                        const req = collection[i];
                        const id = Number(req.docId);
                        const seconds = id * 3600;
                        const milliseconds = seconds * 1000;
                        const date = new Date(milliseconds);
                        
                        const day = date.getDate();
                        const month = date.getMonth() + 1;
                        const hours = date.getHours();
                        const minutes = date.getMinutes();
                        // const year = date.getFullYear();

                        const hrMn = `${hours}:${minutes}`;
                        const formattedDate = `${month}/${day} @ ${hours}:${minutes}0`;

                        const format = {
                            date: formattedDate,
                            "Number of emails generated": req.count,
                            time: hrMn,
                        };
                        final.push(format);

                    }
                    this.setState({
                        data: final,
                        dataLoading: false,
                        selectedData: final.slice(-24), // Select the last 24 hours.
                        // 23 because the most recent hour is usually not yet complete
                    });
                }
            }).catch((error) => {
                // TODO: Log this issue
                console.log(error);
                this.setState({
                    data: [],
                    dataLoading: false,
                });
            });
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        })
    }
    _onSelect = (range) => {
        const data = this.state.data;
        const dateRange = range.value;
        if (dateRange === "All time") {
            this.setState({
                selectedData: data,
            })
            return;
        }
        // Set the data to display
        const hours = rangeMap[`${dateRange}`].hours;
        this.setState({
            selectedData: data.slice(-1 * hours),
        })
    }

    render() {
        return (
            <div className="DashboardVerticalSection">
                <h2> Number of Emails Generated Per Hour  </h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <div className="HorizontalFlex CenterContentsVerticalInHorizontalFlex">
                        <p className="TitleText">Date Range:</p>
                        <Dropdown options={options} onChange={this._onSelect} value={defaultOption} placeholder="Select Date Range" />
                    </div>

                    <br/>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                        width={500}
                        height={300}
                        data={this.state.selectedData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="Number of emails generated" stroke="#8884d8" activeDot={{ r: 8 }} />
                        {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                {/* {this.openProjectModal()} */}
                
            </div>
        );
    }
}


export default EmailsPerHour;
