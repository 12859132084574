import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { ResponsiveContainer } from 'recharts';
import FrequencyDistributionChart from '../Graphs/FrequencyDistributionChart';


class WeekdayDistributionOfEmailVolume extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        const adminMetrics = this.props.adminMetrics ?
            this.props.adminMetrics : {};
        this.state = {
            data: [],
            currentData: [],
            dataLoading: true,
            user: user,
            options: [],
            defaultOption: "",
            adminMetrics: adminMetrics,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {
        const data = this.getValFromAdminMetrics(this.state.adminMetrics);
        
        this.formatNormalDistributionData(data);
    }

    titleCase = (string) => {
        let sentence = string.toLowerCase().split(" ");
        for (let i = 0; i < sentence.length; i++) {
            sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
        }
        return sentence;
    };
    _onSelect = (cohort) => {
        const cohortName = cohort.value;
        // Set the data to display
        const cohortData = this.state.data["cohort"][cohortName];
        this.setState({
            currentData: cohortData,
        });
    }

    getValFromAdminMetrics = (metrics) => {
        let returnVal = {};
        for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];
            const docId = metric.docId;
            if (docId === "weekday-email-generated-volume") {
                returnVal = metric;
            }
        }
        return returnVal;
    }


    formatNormalDistributionData = (d) => {
        if (!(d && d["data"] && d["data"].length)) {
            console.log("Data for weekday email volume normal distribution not available");
            return
        }
        const formattedData = {
            "cohort": {

            }    
        };
        const data = d["data"];
        const options = [];
        let latestCohort = "";
        
        for (let i = 0; i < data.length; i++) {
            const d = data[i];
            const values = d["data"];
            const month = d["month"];
            if (i === 0) latestCohort = month;
            options.push(month);
            formattedData["cohort"][month] = values;

        }
        this.setState({
            currentData: formattedData["cohort"][latestCohort],
            data: formattedData,
            options: options,
            defaultOption: latestCohort,
            dataLoading: false,
        });

        const final = {
            "cohort": {
                "01/02/2023": [],
                "01/03/2023": [],
                "01/05/2023": [],
                "01/09/2023": [],
                "Undefined": [],
            },
            "activated": {
                "01/02/2023": "",
                "01/03/2023": "",
                "01/05/2023": "",
                "01/09/2023": "",
                "Undefined": "",
            },
        }
    }

    openNewTab = (url) => {
        window.open(url, '_blank');
    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };
    onProjectClick = (project) => {
        this.setState({
            selectedProjectInModal: project.data,
            showProjectModal: true,
        });
    }

    render() {
        return (
            <div className="DashboardVerticalSection">
                <h2>Weekday Distribution of Email Volume</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="PendingProjectsList">
                    <div className="HorizontalFlex CenterContentsVerticalInHorizontalFlex">
                        <p className="TitleText">Cohort:</p>
                        <Dropdown options={this.state.options} onChange={this._onSelect} value={this.state.defaultOption} placeholder="Select Cohort" />
                    </div>

                    <br/>
                    
                    <ResponsiveContainer width="100%" height="90%">
                        <FrequencyDistributionChart data={this.state.currentData} binCount={20}/>
                    </ResponsiveContainer>
                </div>
                
            </div>
        );
    }
}


export default WeekdayDistributionOfEmailVolume;
