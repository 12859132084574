export const lightTheme = {
    contentBackground: "#eff3f9",
    text: "#363537",
    toggleBorder: "#4F4F4F",
    background: "#ffffff",
    toggleAlign: "flex-start",
    toggleLightIcon: "flex",
    toggleDarkIcon: "none",
    cardBackground: "#fdfdff",
    cardBorder: "1px solid #ebebeb",
    geoScale: "#eff3f9",
}

export const darkTheme = {
    contentBackground: "#212427",
    text: "#CACACA",
    toggleBorder: "#7F7FD5",
    background: "#7F7FD5",
    toggleAlign: "flex-end",
    toggleLightIcon: "none",
    toggleDarkIcon: "flex",
    cardBackground: "#282C31",
    cardBorder: "1px solid #4D4D4D",
    geoScale: "#4C4C4C",
    accent: "#FE9678",
    accentLegacy: "#FE9678",
    accentDarker: "#EA8B6F",
    accentDarkest: "#DE856A",
    // text: "#FFFFFF",
    socials: "#E5E8EB",
    socialsDark: "#c9c9c9",
    socialsDarker: "#BFC0C1",
    socialsDarkest: "#B3B3B4",
}