import React, { Component } from 'react';
import SnackbarError from "../Snackbar/SnackbarError";
import SnackbarSuccess from "../Snackbar/SnackbarSuccess";
import {ThreeDots} from "react-loader-spinner";

class NumEmailsDataset extends Component {
    snackbarErrorRef = React.createRef();
    snackbarSuccessRef = React.createRef();
    constructor(props) {
        super(props);
        const user = this.props.user ? this.props.user : {};
        const adminMetrics = this.props.adminMetrics ?
            this.props.adminMetrics : {};
        this.state = {
            data: [],
            dataLoading: true,
            user: user,
            adminMetrics: adminMetrics,
            showLoading: false,
            showProjectModal: false,
            selectedProjectInModal: {},
        };
    }

    componentDidMount() {

    }

    _showSnackbarErrorHandler = (message) => {
        this.snackbarErrorRef.current.openSnackBar(message);
    };
    _showSnackbarSuccessHandler = (message) => {
        this.snackbarSuccessRef.current.openSnackBar(message);
    };

    getValFromAdminMetrics(metrics) {
        let count = 0;
        for (let i = 0; i < metrics.length; i++) {
            const metric = metrics[i];
            const docId = metric.docId;
            if (docId === "total-email-dataset") {
                count = metric["total"];
            }
        }
        return count.toLocaleString("en-US");
    }

    render() {
        return (
            <div className="DashboardVerticalSection NumberSectionHuge">
                <h2>Total Number of Emails in Dataset</h2>
                <SnackbarError ref = {this.snackbarErrorRef} />
                <SnackbarSuccess ref = {this.snackbarSuccessRef} />

                {this.state.showLoading &&
                    (<div className="SubmitLoadingContainer">
                        <ThreeDots
                            color="#EA8B6F"
                            visible={this.state.showLoading}
                            height={100} width={100} />

                        <p className="AddingProjectHelperText">
                        loading...</p>
                    </div>
                    )
                }
                <br />
                <div className="LargeTextSectionHuge">
                    <p className="LargeNumHuge">
                            {this.getValFromAdminMetrics(this.props.adminMetrics)}</p>
                </div>

                
            </div>
        );
    }
}


export default NumEmailsDataset;
